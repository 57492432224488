import React from "react";
import AuctionConsulting from "./internal/AuctionConsulting";
import Causes from "./internal/Causes";
import Footer from "./internal/Footer";
import GoodVibes from "./internal/GoodVibes";
import HomeTop from "./internal/HomeTop";
import LinkOuts from "./internal/LinkOuts";
import LiveAuctions from "./internal/LiveAuctions";
import PowerOfMany from "./internal/AuctionEvents";
import PowerOfOne from "./internal/Auctions";
import Tidbits from "./internal/Tidbits";

const HomeComponent = () => (
  <>
    <HomeTop />
    <PowerOfOne />
    <PowerOfMany />
    <Tidbits />
    <LinkOuts />
    <AuctionConsulting />
    <>
      <LiveAuctions />
    </>
    <Causes />
    <GoodVibes />
    <Footer />
  </>
);

export default HomeComponent;
