import React from "react";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";

const GermanRedCross =
  "https://imgix.cosmicjs.com/8195af50-9cf3-11ed-93ee-cb9a2cd68754-RedCross.png";
const Unicef =
  "https://imgix.cosmicjs.com/89a1ed30-9cf3-11ed-93ee-cb9a2cd68754-Unicef.png";
const CalgaryHumane =
  "https://imgix.cosmicjs.com/8e696050-9cf3-11ed-93ee-cb9a2cd68754-CalgaryHumaneSociety.png";
const IslandKidsCancer =
  "https://imgix.cosmicjs.com/92d9b2c0-9cf3-11ed-93ee-cb9a2cd68754-IslandKidsCancerAssociation.png";
const AmericanBrainTumor =
  "https://imgix.cosmicjs.com/97107ea0-9cf3-11ed-93ee-cb9a2cd68754-AmericanBrainTumorAssociation.png";

const Causes = () => (
  <section className="w-full pt-32 pb-32" style={{ background: "#F7F7F7" }}>
    <div className="max-w-6xl w-full mx-auto px-5 flex flex-col justify-between gap-6">
      <h3 className="font-inter text-bidddyGray font-medium text-base md:text-lg">
        Causes impacted by our community, powered by Bidddy.
      </h3>
      <div className="w-full flex md:flex-row flex-col md:flex-wrap justify-between items-center gap-12 md:gap-6">
        <Imgix
          src={`${GermanRedCross}?auto=format`}
          height={53}
          width={138}
          htmlAttributes={{
            loading: "lazy",
            alt: "German Red Cross",
            src: `${GermanRedCross}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
        <Imgix
          src={`${Unicef}?auto=format`}
          height={50}
          width={206}
          htmlAttributes={{
            alt: "Unicef",
            loading: "lazy",
            src: `${Unicef}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
        <Imgix
          src={`${CalgaryHumane}?auto=format`}
          height={70}
          width={120}
          htmlAttributes={{
            alt: "Calgary Humane Society",
            loading: "lazy",
            src: `${CalgaryHumane}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
        <Imgix
          src={`${IslandKidsCancer}?auto=format`}
          height={75}
          width={183}
          htmlAttributes={{
            loading: "lazy",
            alt: "Island Kids Cancer Association",
            src: `${IslandKidsCancer}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
        <Imgix
          src={`${AmericanBrainTumor}?auto=format`}
          height={68}
          width={136}
          htmlAttributes={{
            loading: "lazy",
            alt: "American Brain Tumor Associations",
            src: `${AmericanBrainTumor}?blur=500&px=4&auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
          className="lazyload blur-up"
        />
      </div>
    </div>
  </section>
);

export default Causes;
