import React from "react";
import Imgix from "react-imgix";
import cn from "classnames";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import DefaultButton from "../../Buttons/DefaultButton";
import styles from "../styles/Home.module.scss";

const AuctionEvents = () => {
  const navigateToEvent = () => {
    if (typeof window !== "undefined") {
      window.open("https://www.jotform.com/form/230056389150049", "_blank");
    }
  };

  return (
    <section className="w-full bg-white mb-0 md:pb-32 pb-16 pt-0">
      <div className={styles.powerMany}>
        <div className="flex flex-col gap-2 justify-start h-full">
          <div className="md:w-1/3 w-full flex-col flex justify-start h-full">
            <Imgix
              src="https://imgix.cosmicjs.com/b5810ae0-9c16-11ed-93ee-cb9a2cd68754-PowerOfMany-1.png?auto=format"
              height={887}
              width={984}
              htmlAttributes={{
                loading: "lazy",
                alt: "Instagram Auction Events",
                src: `https://imgix.cosmicjs.com/b5810ae0-9c16-11ed-93ee-cb9a2cd68754-PowerOfMany-1.png?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.powerImage, "lazyload blur-up")}
            />
            <h3 className="font-roboto text-bidddyPink text-lg mb-2 mt-16 uppercase">
              Auction Events
            </h3>
            <h2 className="font-inter text-6xl font-bold text-bidddyAltGray mb-6">
              Does it work
              <br /> for charity events?
            </h2>
            <h4 className="font-cubano text-bidddyGray text-2xl leading-7 mb-3">
              Custom auction page.
              <br />
              One-click bidding.
              <br />
              So much more!
              <br />
            </h4>
            <p className="font-inter font-medium text-base leading-6 mb-10 md:max-w-xs">
              Using Bidddy for your event gets you access to 1-1 auction
              specialists, unlimited items, in-person bidding, and the chance to
              reach new donors.
              <br />
              <strong>All for a flat fee.</strong>
            </p>
            <DefaultButton
              extraClasses={styles.defaultButton}
              onClick={() => navigateToEvent()}
              title="Create an Auction Event"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuctionEvents;
