import React, { useEffect, useState } from "react";
import Imgix from "react-imgix";
import cn from "classnames";
import { navigate } from "gatsby";
import axios from "axios";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import DefaultButton from "../../Buttons/DefaultButton";
import BidddyLogo from "../../Images/BidddyLogo";
import styles from "../styles/Home.module.scss";
import { sendToast } from "../../../utils/helpers";
import { baseURL } from "../../../utils/axios";
import GoogleButton from "../../Inputs/GoogleButton";
import useAuthHook from "../../Hooks/useAuthHook";

interface FacebookQueryState {
  type?: string;
  redirect?: string;
}

const Facebook = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.492 0H9.12399C4.1059 0 0 4.20311 0 9.34011V25.356C0 30.493 4.1059 34.6957 9.12399 34.6957H25.492C30.5105 34.6957 34.6164 30.493 34.6164 25.356V9.34011C34.6164 4.20311 30.5105 0 25.492 0Z"
      fill="#3B5998"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3314 11.6713H22.0287V7.88623H18.9231C14.5253 7.88623 14.6295 11.2028 14.6295 11.6974V14.1945H12.5879V17.9796H14.6295V26.8111H18.657V17.9796H21.3543L22.0287 14.1945H18.657V12.3022C18.657 11.8884 18.91 11.6713 19.3314 11.6713Z"
      fill="white"
    />
  </svg>
);

const HomeTop = () => {
  const [, checkLocationAuth] = useAuthHook();
  const [signupUrl, setSignupUrl] = useState(null);
  const [googleParams, setGoogleParams] = useState("");

  const handleTryBidddy = () => {
    navigate("/signup/");
  };

  const openUrl = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    (async () => {
      setUpGoogleLogin();
      const isAuthed = await checkLocationAuth();
      if (isAuthed) {
        navigate("/dashboard/");
      }
      try {
        await getAndSetSignupUrl();
      } catch (err) {
        sendToast(
          "There is an error retrieving the facebook login url. Please Try again later.",
          { type: "error" }
        );
      }
    })();
  }, []);

  const setUpGoogleLogin = () => {
    const redirectURI = `${window.location.href.split("?")[0]}`;
    const signUpType = "signup";
    const userType = "user";
    setGoogleParams(
      encodeURI(
        `${baseURL}/${signUpType}/google?redirect=${redirectURI}&type=${userType}`
      )
    );
  };

  const getAndSetSignupUrl = async () => {
    // Get Signup URL
    const signupState = { ...facebookInitialState(), type: "SIGNING_UP" };
    const signupParams = new URLSearchParams({
      format: "json",
      state: encodeURIComponent(JSON.stringify(signupState)),
    });
    const response = await axios.get(
      `${baseURL}/signup/facebook?${signupParams.toString()}`
    );
    setSignupUrl(response.data.url);
  };

  const facebookInitialState = () => {
    const state = {} as FacebookQueryState;
    const queryString = window.location.search;
    const url = new URLSearchParams(queryString);
    if (url.has("redirect")) {
      state.redirect = url.get("redirect");
    }

    return state;
  };

  return (
    <section className="w-full bg-light-gray-0 mb-0 md:py-32 py-16">
      <div className={styles.homeTop}>
        <div className="flex flex-row gap-2 justify-between h-full">
          <div className="md:w-1/2 w-full flex-col flex justify-center md:items-start h-full sm:items-center">
            <Imgix
              src="https://imgix.cosmicjs.com/0ab38820-9aa6-11ed-93ee-cb9a2cd68754-HomeTop.png?auto=format"
              height={776}
              width={1313}
              htmlAttributes={{
                loading: "lazy",
                alt: "Power your Instagram auctions with Bidddy",
                src: `https://imgix.cosmicjs.com/0ab38820-9aa6-11ed-93ee-cb9a2cd68754-HomeTop.png?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className={cn(styles.topImage, "lazyload blur-up")}
            />
            <BidddyLogo className="text-bidddyPink md:mb-6 w-28 md:w-auto" />
            <h1
              className="md:text-6xl text-4xl w-2/3 md:mb-6 mb-3 font-inter text-bidddyAltGray font-bold sm:text-center text-left md:text-left"
              style={{ minWidth: 380 }}
            >
              Easily Run
              <br /> Auctions on Instagram
            </h1>
            <p className="text-sm max-w-sm font-inter leading-6 text-bidddyAltGray sm:text-center md:text-left text-left">
              Bidddy helps individuals and organizations run automated social
              auctions that drive awareness, generate engagement and take
              fundraising to a whole new level.
            </p>
            <div className="flex flex-col gap-2 mt-6 sm:items-center items-start md:items-start">
              <GoogleButton className={styles.googleButton} href={googleParams}>
                Sign Up With Google
              </GoogleButton>
              <button
                className={styles.facebookButton}
                tabIndex={0}
                disabled={!signupUrl}
                type="button"
                onKeyDown={() => openUrl(signupUrl)}
                onClick={() => openUrl(signupUrl)}
              >
                <Facebook />
                Sign Up With Facebook
              </button>
              <div className={styles.or}>or</div>
              <DefaultButton
                extraClasses={styles.defaultButtonFull}
                onClick={() => handleTryBidddy()}
                title="Sign Up with Email"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTop;
