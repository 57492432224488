/* eslint-disable global-require */
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "aos/dist/aos.css";
import Layout from "../components/Layout";
import HomeComponent from "../components/Home";

const homeData = {
  "@context": "http://schema.org",
  "@type": "WebSite",
  mainEntity: {
    "@type": "Organization",
    "@id": "https://bidddy.com/",
    name: "Bidddy",
    url: "https://bidddy.com/",
    foundingDate: "2021",
    logo: {
      "@type": "ImageObject",
      url: "https://imgix.cosmicjs.com/e7b63c80-de97-11eb-a12b-17ba28648e41-BidddyLogo.png",
    },
    description:
      "Bidddy is a tool that allows you to run comment driven auctions on Instagram. This provides businesses of any size a new way to grow sales and online presence.",
    sameAs: [
      "https://www.linkedin.com/company/bidddy/",
      "https://www.facebook.com/getbidddy/",
      "https://twitter.com/getbidddy",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Bidddy Team",
      email: "hello@bidddy.com",
      url: "https://bidddy.com/",
    },
  },
};

const Home = () => {
  const { t } = useTranslation();
  let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Layout
      hideFooter
      structured_data={homeData}
      showHomeFAQ
      image="https://imgix.cosmicjs.com/1ea993f0-de97-11eb-a12b-17ba28648e41-HomeOgImage.png"
      description={t("home_description")}
      title={t("home_title")}
      canonical="https://bidddy.com/"
    >
      <HomeComponent />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
