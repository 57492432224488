import React from "react";

const Tidbits = ({ background = "bg-white" }) => (
  <section className={`w-full ${background} pt-0 pb-32`}>
    <div className="max-w-6xl w-full mx-auto px-5 flex md:flex-row flex-col justify-between gap-8">
      <div className="md:w-1/3 flex-col flex justify-start">
        <h2 className="text-bidddyAltGray font-inter font-bold text-4xl leading-10 mb-6">
          Generate
          <br /> Awareness
        </h2>
        <p className="font-inter font-normal text-base leading-6 mb-0 md:max-w-xs">
          Social auctions reach and attract more followers to your feed,
          resulting in more buzz for your event and more awareness of your brand
          and cause.
        </p>
      </div>
      <div className="md:w-1/3 flex-col flex justify-start">
        <h2 className="text-bidddyAltGray font-inter font-bold text-4xl leading-10 mb-6">
          Drive
          <br /> Engagement
        </h2>
        <p className="font-inter font-normal text-base leading-6 mb-0 md:max-w-xs">
          Social auctions increase activity, engagement and return visits to
          your feed
        </p>
      </div>
      <div className="md:w-1/3 flex-col flex justify-start">
        <h2 className="text-bidddyAltGray font-inter font-bold text-4xl leading-10 mb-6">
          Increase
          <br /> Fundraising
        </h2>
        <p className="font-inter font-normal text-base leading-6 mb-0 md:max-w-xs">
          With more reach comes more bidders and competition, resulting in more
          bid activity and higher auction sale prices.
        </p>
      </div>
    </div>
  </section>
);

export default Tidbits;
