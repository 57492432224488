import { Link } from "gatsby";
import React from "react";

const ArrowIcon = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8146 12.7631H0.828125V10.2494H18.8146L11.0545 2.33999L12.7984 0.5625L23.5356 11.5062L12.7984 22.45L11.0545 20.6725L18.8146 12.7631Z"
      fill="#ABABAB"
    />
  </svg>
);

const LinkOutCard = ({ title, description, image, link, size = "50%" }) => (
  <Link
    style={{
      height: 200,
      backgroundImage: `url(${image})`,
      backgroundSize: size,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right 30px",
    }}
    to={link}
    className="bg-bidddyLinkOutGray md:px-8 md:py-7 p-4 rounded-lg w-full"
  >
    <div className="w-1/3 md:w-1/2 flex flex-col justify-between h-full">
      <h3 className="text-bidddyGray font-cubano text-2xl leading-6 mb-3">
        {title}
      </h3>
      <p className="font-roboto font-bold text-base text-bidddyGray uppercase mb-4 leading-4">
        {description}
      </p>
      <ArrowIcon />
    </div>
  </Link>
);

const LinkOuts = () => (
  <section className="w-full bg-bidddyHomeDarkGray pt-0">
    <div className="max-w-6xl w-full mx-auto px-5 flex md:flex-row flex-col justify-between gap-8 pb-16 pt-24">
      <LinkOutCard
        title={
          <>
            Why
            <br /> Bidddy
          </>
        }
        description="Benefits & Features"
        image="https://imgix.cosmicjs.com/5174ba70-9c29-11ed-93ee-cb9a2cd68754-character.png?auto=format"
        link="/features"
      />
      <LinkOutCard
        title="How it works"
        size="75%"
        description={
          <>
            Starter <br />
            Guide
          </>
        }
        image="https://imgix.cosmicjs.com/56708400-9c29-11ed-93ee-cb9a2cd68754-HowItWorksStarterGuide.png?auto=format"
        link="/learn/how-it-works/"
      />
      <LinkOutCard
        title="Case Studies"
        description={
          <>
            View Case
            <br /> Studies
          </>
        }
        image="https://imgix.cosmicjs.com/44f08900-9c29-11ed-93ee-cb9a2cd68754-NanaoKimono.png?auto=format"
        link="/case-studies/"
      />
    </div>
  </section>
);

export default LinkOuts;
