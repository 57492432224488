import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { format } from "date-fns";
import cuid from "cuid";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import {
  getLiveAuctions,
  PublicAuctionItem,
} from "../../../store/liveAuctions/actions";
import { useAppDispatch } from "../../../store";
import { selectLiveAuctions } from "../../../store/liveAuctions/selectors";
import styles from "../../Public/PublicMainAuction.module.scss";
import { getCurrencyLocaleValue } from "../../../utils/helpers";

interface LiveAuctionProps {
  auction: PublicAuctionItem;
}

const AWAITING_PLACEHOLDER = `https://imgix.cosmicjs.com/24e36110-a7a0-11ec-ad74-ab832bebcff9-AwaitingConnection.png`;

const LiveAuctionItem = ({ auction }: LiveAuctionProps) => {
  const getBannerImage = () => {
    switch (auction.progress) {
      case "publish":
        return <div className={cn([styles.bannerSm, styles.live])}>LIVE</div>;
      case "finish":
        return (
          <div className={cn([styles.bannerSm, styles.closed])}>CLOSED</div>
        );
      default:
        return (
          <div className={cn([styles.bannerSm, styles.closed])}>
            COMING SOON
          </div>
        );
    }
  };

  return (
    <a
      href={auction.instagram_url}
      className={styles.publicAuctionContainer}
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.publicAuctionImageContainer}>
        {getBannerImage()}
        {auction && auction.image_url ? (
          <Imgix
            className="min-h-full min-w-full object-cover lazyload blur-up"
            src={`${auction.image_url}?auto=format`}
            sizes="33.3vw"
            imgixParams={{ ar: "1:1" }}
            htmlAttributes={{
              loading: "lazy",
              alt: "Public Auction Image",
              src: `${auction.image_url}?auto=format&blur=100&px=2`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
          />
        ) : (
          <Imgix
            src={`${AWAITING_PLACEHOLDER}?auto=format`}
            sizes="33.3vw"
            imgixParams={{ ar: "1:1" }}
            htmlAttributes={{
              loading: "lazy",
              alt: "Public Auction Image",
              src: `${AWAITING_PLACEHOLDER}?blur=100&px=2&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="min-h-full min-w-full object-cover lazyload blur-up"
          />
        )}
      </div>
      <div className={styles.auctionContentContainer}>
        <div className={styles.auctionHeaders}>
          <h3>{auction.name || "Auction"}</h3>
        </div>
        <div className={cn(styles.auctionContentRowContainer, "w-full")}>
          <p>
            {format(new Date(auction.starts_at), "MMM dd yyyy")} -{" "}
            {format(new Date(auction.ends_at), "MMM dd yyyy")}
          </p>
          <p className="flex flex-row justify-end">
            <span>
              <span className={styles.bold}>
                {auction &&
                  auction.highest_bid &&
                  !auction.highest_bid.bin &&
                  getCurrencyLocaleValue(
                    auction.highest_bid.currency,
                    auction.highest_bid.amount / 100 // TODO: Fix later
                  )}
              </span>
              ({auction.num_bids || 0} bids
              {auction.num_bins > 0 && ` + ${auction.num_bins} BINs`})
            </span>
          </p>
        </div>
      </div>
    </a>
  );
};

const LiveAuctions = () => {
  const dispatch = useAppDispatch();
  const publicAuctions = useSelector((state) => selectLiveAuctions(state));

  useEffect(() => {
    // FETCH AUCTIONS
    dispatch(getLiveAuctions());
  }, []);

  return (
    <section
      className={cn(
        "w-full mx-auto flex flex-col max-w-6xl pl-5 md:px-5 md:pt-16 py-8"
      )}
    >
      {publicAuctions && publicAuctions.length > 0 && (
        <>
          <h2
            id="bidddy-live"
            className="font-inter text-bidddyAltGray text-5xl font-bold"
          >
            Bidddy Live
          </h2>
          <p className="mt-3 font-inter text-bidddyGray md:text-lg">
            Check out some of the latest auctions.
          </p>
          <div className="py-16 flex flex-row md:flex-wrap overflow-auto">
            {publicAuctions &&
              publicAuctions
                .slice(0, 9)
                .map((auction) => (
                  <LiveAuctionItem auction={auction} key={cuid()} />
                ))}
          </div>
        </>
      )}
    </section>
  );
};

export default LiveAuctions;
